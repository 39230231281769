import classNames from 'classnames'
import { useState } from 'react'
import { Accordion, TextBlock } from '@/components/common'
import { Document } from '@contentful/rich-text-types'
import Markdown from '@/components/Markdown'

interface qAndA {
  answer: string | Document
  question: string
}

interface Props {
  faq: {
    questions: qAndA[]
  }
}

function FAQ({ faq }: Props) {
  const [expanded, setExpanded] = useState(-1)

  if (faq.questions.length === 0) {
    return null
  }

  return (
    <Accordion expanded={expanded} setExpanded={setExpanded} speed="fast">
      {faq.questions.map((item, idx) => (
        <div
          key={item.question}
          className={classNames({
            'transition-all ease-in-out duration-300': true,
            'py-4': expanded === idx,
          })}
        >
          <div
            className={classNames({
              'border border-gray-7': true,
              'border-t-0':
                idx !== 0 && expanded !== idx && expanded + 1 !== idx,
            })}
          >
            <Accordion.Summary index={idx} className="w-full p-4">
              {item.question}
            </Accordion.Summary>

            <Accordion.Details index={idx} className="px-4 pb-4">
              {typeof item.answer === 'object' ? (
                <TextBlock document={item.answer} />
              ) : (
                <Markdown source={item.answer} />
              )}
            </Accordion.Details>
          </div>
        </div>
      ))}
    </Accordion>
  )
}

export default FAQ
