import { GetStaticProps } from 'next'
import React from 'react'
import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { resolveSiteAssets } from '@/services/api/siteAssets/resolver'

import LandingPage, { Props } from '@/components/pages/landing/LandingPage'
import { getAllCampaigns } from '@/services/api/campaign'
import { getContent } from '@/services/contentful'
import { whiteLabelUtil } from '@/utils'

export const Home: React.FC<Props> = ({
  allCampaigns,
  partnerCampaigns,
  siteAssets,
}) => {
  return (
    <main className="bg-gray-100 h-full flex-grow min-h-screen">
      <LandingPage
        allCampaigns={allCampaigns}
        partnerCampaigns={partnerCampaigns}
        siteAssets={siteAssets}
      />
    </main>
  )
}

export default Home

export const getStaticProps: GetStaticProps = async () => {
  const allCampaigns = await getAllCampaigns()
  const siteAssets = await getContent({
    content_type: 'siteAssets',
    'fields.contentfulName': 'invest.angel.com',
  }).then((res) =>
    resolveSiteAssets(res?.items?.[0]?.fields as ISiteAssetsFields)
  )

  return {
    props: {
      allCampaigns: allCampaigns.filter((c) =>
        whiteLabelUtil.isAllowed(c, 'invest.angel.com')
      ),
      partnerCampaigns: allCampaigns.filter(
        (c) => !whiteLabelUtil.isAllowed(c, 'invest.angel.com')
      ),
      siteAssets,
    },
  }
}
