import React from 'react'
import { Text } from '@/components/common'
import classNames from 'classnames'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import { IFaqSectionFields } from '../../../types/generated/contentful'

export interface Props {
  siteFaqs: IFaqSectionFields
}

export const FaqSection: React.FC<Props> = ({ siteFaqs }) => {
  const faq = {
    questions: siteFaqs.faqs.map((faq) => {
      return { answer: faq.fields.answer, question: faq.fields.question }
    }),
  }
  return (
    <div className="max-w-[1168px] mb-8 px-4 w-full bg-white">
      <Text
        as="h3"
        preset="heading.lg md:heading.xl"
        className="md:inline-block md:mr-2"
      >
        {siteFaqs.title}
      </Text>
      {siteFaqs.subtitle && (
        <Text
          as="p"
          preset="heading.sm md:heading.lg"
          className="font-normal text-gray-3 md:inline-block"
        >
          {siteFaqs.subtitle}
        </Text>
      )}
      <div className={classNames('w-full overflow-hidden gap-6 mt-6')}>
        <FAQ faq={faq} />
      </div>
    </div>
  )
}

export default FaqSection
