import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Campaign } from '@/services/api/campaign'
import CampaignSection from './CampaignSection'
import useCampaigns from '@/services/hooks/useCampaigns'
import { ISiteAssetsFields } from 'src/types/generated/contentful'
import LandingPageHero from './LandingPageHero'
import useAuth from '@/services/hooks/useAuth'
import FaqSection from '@/components/pages/landing/FaqSection'

// closed most recently to longest ago
const sortFunc = (a: Campaign, b: Campaign) => {
  // handle null cases
  if (b.hoursUntilClose === null && a.hoursUntilClose === null) {
    return 0
  } else if (a.hoursUntilClose === null) {
    return 1
  } else if (b.hoursUntilClose === null) {
    return -1
  }

  return b.hoursUntilClose - a.hoursUntilClose
}
// closing soonest to latest
const tWSortFunc = (a: Campaign, b: Campaign) => {
  // handle null cases
  if (b.hoursUntilClose === null && a.hoursUntilClose === null) {
    return 0
  } else if (a.hoursUntilClose === null) {
    return 1
  } else if (b.hoursUntilClose === null) {
    return -1
  }

  return a.hoursUntilClose - b.hoursUntilClose
}

export interface Props {
  allCampaigns: Campaign[]
  partnerCampaigns: Campaign[]
  siteAssets: ISiteAssetsFields
}

const LandingPage: React.FC<Props> = ({
  allCampaigns,
  partnerCampaigns,
  siteAssets,
}) => {
  const { openModal: openAuthModal } = useAuth()
  const router = useRouter()
  const { campaigns = allCampaigns } = useCampaigns()

  useEffect(() => {
    if (router.query.auth) {
      openAuthModal()
    }
  }, [router, openAuthModal])

  const [currentlyFunding, testingWaters, offeringClosed] = useMemo(() => {
    const funding: Campaign[] = []
    const testing: Campaign[] = []
    const closed: Campaign[] = []

    campaigns.forEach((campaign) => {
      // guards against live projects that we don't want to be listed
      // TODO: we should use some other indicator, especially if we move to getting images from Contentful
      if (!campaign.previewImg) return

      if (!campaign.currentlyFunding) {
        closed.push(campaign)
      } else if (campaign.regulationType !== 'testing_the_waters') {
        funding.push(campaign)
      } else {
        testing.push(campaign)
      }
    })

    return [funding, testing.sort(tWSortFunc), closed.sort(sortFunc)]
  }, [campaigns])

  return (
    <>
      <LandingPageHero siteAssets={siteAssets} />

      <div className="bg-gray bg-opacity-10 flex flex-col items-center pt-8">
        {!!currentlyFunding.length && (
          <CampaignSection
            campaigns={currentlyFunding}
            data-cy="currently-funding-group"
            title="Currently Funding"
          />
        )}

        {!!partnerCampaigns.length && (
          <CampaignSection
            campaigns={partnerCampaigns}
            data-cy="partner-funding-group"
            title="Check out what DAVID is up to now"
          />
        )}

        {!!testingWaters.length && (
          <CampaignSection
            campaigns={testingWaters}
            data-cy="gauging-interest-group"
            title="Gauging Interest"
          />
        )}

        {!!offeringClosed.length && (
          <CampaignSection
            campaigns={offeringClosed}
            data-cy="not-currently-funding-group"
            title="Successfully Funded"
          />
        )}
      </div>
      {siteAssets?.siteFaQs?.fields && (
        <div className="flex flex-col items-center pt-8">
          <FaqSection siteFaqs={siteAssets.siteFaQs?.fields} />
        </div>
      )}
    </>
  )
}

export default LandingPage
