import React, { useState } from 'react'
import { Button, ButtonBase, MarkdownModal, Text } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'
import Link from 'next/link'
import { ISiteAssetsFields } from 'src/types/generated/contentful'

export interface Props {
  siteAssets: ISiteAssetsFields
}

const LandingPageHero: React.FC<Props> = ({ siteAssets }) => {
  const { user, openModal: openAuthModal } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className="bg-black flex flex-col md:flex-row">
      <div className="flex flex-col h-auto py-10 px-6 justify-center w-full items-center">
        <Text
          as="h2"
          preset="custom"
          className="text-white font-bold text-3xl pb-6 text-center"
          data-cy="main-tagline"
        >
          Invest in stories that amplify light
        </Text>
        {!user && (
          <Button
            className="mt-6 uppercase"
            data-cy="create-investment-account-button"
            onClick={() => openAuthModal()}
          >
            Create Investment Account
          </Button>
        )}
        <Link href="/apply">
          <a>
            <Button
              variant="secondary"
              color="white"
              className="mt-6 uppercase"
              data-cy="apply-to-fund-your-project-button"
            >
              Apply to Fund Your Project
            </Button>
          </a>
        </Link>
        {siteAssets.howCrowdfundingWorks && (
          <ButtonBase
            className="text-primary mt-6 cursor-pointer text-xs"
            data-cy="read-about-crowdfunding-risks-button"
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            Read About Crowdfunding Risks
          </ButtonBase>
        )}
      </div>

      {siteAssets.howCrowdfundingWorks && (
        <MarkdownModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          info={siteAssets.howCrowdfundingWorks}
        />
      )}
    </div>
  )
}

export default LandingPageHero
